.App {
  text-align: center;
  /* background-color: #282c34; */
  /* background-color: rgb(22, 21, 21); */
  /* background-color: rgb(12, 12, 12); */
  background-color: white;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: black;
  font-family: Consolas;
}


::-webkit-scrollbar {        
  /* height: 10px; */
  background-color: black;
}

::-webkit-scrollbar-thumb {        
  border-radius:10px;
  /* height: 10px; */
  background-color: grey;
}



.App-link {
  color: #09d3ac;
}

