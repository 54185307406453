
.track-modal {
  width: 100vw;
  height:100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 10;
  position: fixed;
  display:flex;
  justify-content: center;
  align-content: center;
  opacity: 1;
  padding: 3vmin;
	animation-name: fadeInTrack;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
  animation-duration: 600ms;
  transition: width 500ms ease-out;
  
}

.track-modal-frame {
  border: 3px solid black;
  margin-top:25vmin;
  width:65vmin;
  opacity: 1;
	animation-name: fadeInModal;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
  animation-duration: 500ms;
  transition: width 500ms ease-out;
}

@media only screen and (max-width: 600px) {
  .track-modal-frame {
    border: 3px solid black;
    margin-top:25vmin;
    width:80vmin;
    opacity: 1;
    animation-name: fadeInModal;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 500ms;
    transition: width 500ms ease-out;
  }

  @keyframes fadeInModal {
    0% {
      opacity: 0;
      width: 0;
    }
  
    100% {
      opacity: 1;
      width: 80vmin;
      
    }
  }
}



@keyframes fadeInTrack {
	0% {
    opacity: 0;
  }

	100% {
    opacity: 1;
	}
}

@keyframes fadeInModal {
	0% {
    opacity: 0;
    width: 0;
  }

	100% {
    opacity: 1;
    width: 65vmin;
    
	}
}

