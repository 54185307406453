
.track {
  /* flex: 0 0 auto; */
  position: relative;
  width: 42vmin;
  align-self: center;
  opacity: 1;
  padding: 4vmin;
	animation-name: fadeInTrack;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
  animation-duration: 600ms;
  /* transition: width 1s ease-out; */
  transition: 300ms ease-out;
  
}

.track-image {
  width:100%;
  opacity: 1;
	animation-name: fadeInTrackImage;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
  animation-duration: 600ms;
  transition: width 300ms ease-out;
}

.track:hover{
  transform: scale(1.1);
  /* width: 34vmin; */
  /* transition: width 500ms ease-out; */
  transition: 300ms ease-out;
  cursor: pointer;
}

.track-name{
  color: black;
  font-size:75%;
  transition: font-size 500ms ease-out;
  margin-top: 2vmin;
}


.track-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 3em;
  width: 100%;
  height: 100%;
  color: white;
  top: -3vmin;
  left: 0;
  transition: 500ms;
  opacity: 1;

  animation-name: fadeInTrack;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
  animation-duration: 600ms;
}

.track-overlay:hover {
  opacity: 0;
}

@keyframes fadeInTrack {
	0% {
    opacity: 0;
  }

	100% {
    opacity: 1;
	}
}

@keyframes fadeInTrackImage {
	0% {
    opacity: 0;
    width: 75%;
  }

	100% {
    opacity: 1;
    width: 100%;
    
	}
}

