

.left {
  float:left;
  /* padding: 0 20px 20px 0; */
}

.pad{
  padding: 0 20px 20px 0;
}


